import React, { useEffect } from 'react'
import { GetStaticProps } from 'next'
import { isAcademyExAu, isAcademyexApp } from '../lib/detectEnvironment'
import ContentPage, {
  ContentPageProps,
  generateStaticContentPageProps,
} from '../components/ContentPage/ContentPage'
import { homePageVersion } from '../types/types'
import { getCookie, deleteCookie } from 'cookies-next'
import * as Sentry from '@sentry/nextjs'

const IndexPage = ({ page, menu, footer, graphqlResources, slug }: ContentPageProps) => {
  useEffect(() => {
    // Delete the AB testing cookie, we can remove this once we consider it appropriate
    if (isAcademyexApp()) {
      try {
        const cookie = getCookie('aexABTest')

        if (cookie?.length) {
          deleteCookie('aexABTest')
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  }, [])

  return (
    <ContentPage
      page={page}
      menu={menu}
      footer={footer}
      graphqlResources={graphqlResources}
      slug={slug}
    />
  )
}
export default IndexPage

export const getStaticProps: GetStaticProps<ContentPageProps> = async () => {
  const isAexAu = isAcademyExAu()

  const indexSlug = isAexAu ? homePageVersion.SUBDOMAIN : homePageVersion.DEFAULT

  return generateStaticContentPageProps(indexSlug)
}
